import * as React from "react";

import { usePage } from "./usePage";
import { getOptions } from "./utils";
import { Fields } from "..";
import { getLang, getSiteID } from "../functions/utils";

/**
 * `useContentTypeNavigation<ContentType>()`
 *
 * Returns an object with previous and next ContentType items. This hooks only works in the context of the Griddo editor.
 *
 * @param data The property of the ReferenceField.
 *
 * @example
 * const { previous, next } = useContentTypeNavigation<NewsContentType>(data)
 */
function useContentTypeNavigation<ContentType = unknown>(
	data?: Fields.ReferenceNavigation
): Fields.QueriedNavigationDataItem<ContentType> {
	const [response, setResponse] = React.useState<
		Fields.QueriedNavigationDataItem<ContentType>
	>({ previous: [], next: [], error: undefined });

	const { apiUrl, canonicalURL, structuredDataContent } = usePage();

	const {
		order = "recent-asc",
		quantity = 1,
		fullRelations = false,
		referenceId = structuredDataContent?.id,
	} = data || {};

	React.useEffect(() => {
		const lang = getLang();
		const isAX = !!lang;
		const siteID = getSiteID() || canonicalURL || "global";
		const url = `${apiUrl}/site/${siteID}/distributor`;
		const body = {
			mode: "navigation",
			order,
			quantity,
			fullRelations,
			referenceId,
		};

		const fetchOptions = getOptions(body);

		isAX &&
			fetch(url, fetchOptions)
				.then((res) => res.json())
				.then((data) => setResponse(data))
				.catch((error) => {
					console.error("Error:", error);
				});
	}, [order, quantity, fullRelations, referenceId]);

	return response;
}

export { useContentTypeNavigation };
