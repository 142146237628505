import React, { useEffect, useCallback, useState, useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";
import Tree, {
  mutateTree,
  moveItemOnTree,
  RenderItemParams,
  TreeItem,
  TreeData,
  ItemId,
  TreeSourcePosition,
  TreeDestinationPosition,
} from "@atlaskit/tree";

import { structuredDataActions } from "@ax/containers/StructuredData";
import { appActions } from "@ax/containers/App";
import {
  IRootState,
  IStructuredData,
  IGetStructuredDataParams,
  IDataPack,
  IStructuredDataCategory,
  ICategoryGroup,
  IOrderCategoryParams,
  IQueryValue,
} from "@ax/types";
import { useBulkSelection, useModal, usePermission, useToast } from "@ax/hooks";
import {
  MainWrapper,
  TableList,
  ErrorToast,
  Toast,
  EmptyState,
  Icon,
  SearchTagsBar,
  FilterTagsBar,
} from "@ax/components";
import { sortBy } from "@ax/helpers";

import { DeleteGroupModal, DeleteModal } from "./atoms";
import { filterCategoriesAndGroups, getAllLangCategoriesIds } from "./utils";
import CategoryItem from "./CategoryItem";
import CategoryPanel from "./CategoryPanel";
import CategoryNav from "./CategoryNav";
import BulkHeader from "./BulkHeader";
import {
  findChild,
  findChildByIndex,
  formatItem,
  getElementsFromTree,
  getIDsFromTree,
  normalizeItems,
} from "./helpers";
import { useFilterQuery } from "./hooks";

import * as S from "./style";

const CategoriesList = (props: IProps): JSX.Element => {
  const {
    currentSiteID,
    currentStructuredData,
    currentDataContent,
    getStructuredDataContents,
    lang,
    siteLanguages,
    globalLangs,
    setLanguage,
    totalItems,
    categories,
    setSelectedCategory,
    activatedDataPacks,
    deleteDataContent,
    setHistoryPush,
    resetCurrentData,
    deleteCategoryGroup,
    orderCategory,
  } = props;

  const [tree, setTree] = useState<TreeData>({ rootId: "root", items: {} });
  const [isScrolling, setIsScrolling] = useState(false);
  const [deleteGroupCategories, setDeleteGroupCategories] = useState(false);
  const [itemDragging, setItemDragging] = useState<ItemId | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { isVisible, toggleToast, setIsVisible, state: toastState } = useToast();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isGroupOpen, toggleModal: toggleGroupModal } = useModal();
  const tableRef = useRef<HTMLDivElement>(null);
  const { setFiltersSelection, resetFilterQuery, filterValues, filterQuery } = useFilterQuery();

  const allowedToCreateSiteCategory = usePermission("categories.createSiteTaxonomies");
  const allowedToCreateGlobalCategory = usePermission("global.globalData.createTaxonomies");
  const allowedToDeleteSiteCategory = usePermission("categories.deleteSiteTaxonomies");
  const allowedToDeleteGlobalCategory = usePermission("global.globalData.deleteTaxonomies");
  const allowedToDeleteTaxonomy = currentSiteID ? allowedToDeleteSiteCategory : allowedToDeleteGlobalCategory;
  const allowedToCreateTaxonomy = currentSiteID ? allowedToCreateSiteCategory : allowedToCreateGlobalCategory;

  const scope = currentSiteID ? "site" : "global";
  const currentCategories = categories[scope].sort(sortBy("title", false));

  const catIds = getIDsFromTree(currentDataContent);

  const {
    resetBulkSelection,
    selectedItems,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
    setHoverCheck,
  } = useBulkSelection(catIds);

  const getParams = useCallback((): IGetStructuredDataParams => {
    return {
      page: 1,
      itemsPerPage: 50,
      pagination: false,
      deleted: false,
      include_draft: false,
      groupingCategories: true,
      query: searchQuery,
      filterQuery,
    };
  }, [searchQuery, filterQuery]);

  const getContents = useCallback(
    async (id: string) => {
      const params = getParams();
      params.dataID = id;
      await getStructuredDataContents(params, currentSiteID);
    },
    [getStructuredDataContents, currentSiteID, getParams]
  );

  useLayoutEffect(() => {
    if (currentCategories.length) {
      setSelectedCategory(currentCategories[0].id, scope);
    }
    return () => {
      resetCurrentData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentStructuredData?.taxonomy && getContents(currentStructuredData.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, currentStructuredData, searchQuery, filterValues]);

  useEffect(() => {
    setTree(normalizeItems(currentDataContent, tree));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataContent]);

  const handleClick = (dataID: string) => {
    setSelectedCategory(dataID, scope);
  };

  const { isOpen, toggleModal } = useModal();

  const rightButtonProps = allowedToCreateTaxonomy
    ? {
        label: "New",
        action: toggleModal,
      }
    : undefined;

  const bulkDelete = async () => {
    const { groups, categories } = filterCategoriesAndGroups(currentDataContent);
    const idsCatsToBeDeleted = getAllLangCategoriesIds(categories, selectedItems);
    const idsGroupsToBeDeleted = getAllLangCategoriesIds(groups, selectedItems);
    const deletedCats = idsCatsToBeDeleted.length && (await deleteDataContent(idsCatsToBeDeleted, false));
    const deletedGroups =
      idsGroupsToBeDeleted.length && (await deleteCategoryGroup(idsGroupsToBeDeleted, deleteGroupCategories, false));
    if (deletedCats || deletedGroups) {
      currentStructuredData && getContents(currentStructuredData.id);
      toggleToast(`${selectedItems.all.length} categories and/or groups deleted`);
      unselectAllItems();
      isDeleteOpen && toggleDeleteModal();
      isGroupOpen && toggleGroupModal();
    }
  };

  const handleToggleDeleteModal = () => {
    const selectedCategories = currentDataContent.filter((category) => selectedItems.all.includes(category.id));
    const hasGroups = selectedCategories.some((category) => category.type === "group");
    hasGroups ? toggleGroupModal() : toggleDeleteModal();
  };

  const unselectAllItems = () => resetBulkSelection();

  const selectItems = () => (checkState.isAllSelected ? unselectAllItems() : handleSelectAll());

  const handleSelectAll = () => selectAllItems();

  const onScroll = (e: any) => setIsScrolling(e.target.scrollTop > 0);

  const filterItems = async (filterPointer: string, filtersSelected: IQueryValue[]) =>
    setFiltersSelection(filterPointer, filtersSelected);

  const toastProps = {
    setIsVisible,
    message: toastState,
  };

  const bulkActions = allowedToDeleteTaxonomy
    ? [
        {
          icon: "delete",
          text: "delete",
          action: handleToggleDeleteModal,
        },
      ]
    : [];

  const TableHeader = (
    <BulkHeader
      showBulk={areItemsSelected(catIds)}
      selectAllItems={handleSelectAll}
      totalItems={totalItems}
      selectItems={selectItems}
      checkState={checkState}
      isScrolling={isScrolling}
      bulkActions={bulkActions}
      filterValues={filterValues}
      filterItems={filterItems}
      setHoverCheck={setHoverCheck}
    />
  );

  const availableLanguages = currentSiteID ? siteLanguages : globalLangs;

  const isTranslatable = currentStructuredData ? currentStructuredData.translate : true;

  const languageActions = {
    setLanguage,
  };

  const getIcon = (item: TreeItem, onExpand: (itemId: ItemId) => void, onCollapse: (itemId: ItemId) => void) => {
    const handleCollapse = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent) => {
      e.stopPropagation();
      onCollapse(item.id);
    };

    const handleExpand = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent) => {
      e.stopPropagation();
      onExpand(item.id);
    };

    if (item.children && item.children.length > 0) {
      return item.isExpanded ? (
        <div onClick={handleCollapse} role="button" tabIndex={0} onKeyDown={handleCollapse}>
          <Icon name="UpArrow" />
        </div>
      ) : (
        <div onClick={handleExpand} role="button" tabIndex={0} onKeyDown={handleExpand}>
          <Icon name="DownArrow" />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderItem = ({ item, onExpand, onCollapse, provided }: RenderItemParams) => {
    const category = formatItem(item, tree);
    const isItemSelected = isSelected(category.id);
    return (
      <S.Draggable ref={provided.innerRef} {...provided.draggableProps}>
        <CategoryItem
          category={category}
          key={category.id}
          languages={availableLanguages}
          lang={lang}
          isTranslatable={isTranslatable}
          isSelected={isItemSelected}
          onChange={addToBulkSelection}
          toggleToast={toggleToast}
          getContents={getContents}
          icon={getIcon(item, onExpand, onCollapse)}
          dragHandleProps={provided.dragHandleProps}
          hoverCheck={checkState.hoverCheck}
        />
      </S.Draggable>
    );
  };

  const onExpand = (itemId: ItemId) => {
    const newTree = mutateTree(tree, itemId, { isExpanded: true });
    setTree(newTree);
  };

  const onCollapse = (itemId: ItemId) => {
    const newTree = mutateTree(tree, itemId, { isExpanded: false });
    setTree(newTree);
  };

  const onDragStart = (item: ItemId) => setItemDragging(item);

  const onDragEnd = async (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    if (!destination) {
      setItemDragging(null);
      return;
    }

    if (destination.index === source.index && destination.parentId === source.parentId) {
      setItemDragging(null);
      return;
    }

    const item = itemDragging ? findChild(tree, itemDragging) : null;

    if (!item) {
      setItemDragging(null);
      return;
    }

    const { parentId } = destination;
    const parentIdNumber = parentId === "root" ? 0 : parseInt(parentId as string);
    const parent: any = getElementsFromTree(tree).find((data: any) => data.id === parentIdNumber);

    if (parentId === "root" || parent?.type === "group") {
      const newTree = moveItemOnTree(tree, source, destination);
      newTree.items[parentId].isExpanded = true;
      setTree(newTree);

      const isGoingUp =
        (destination.parentId === source.parentId &&
          destination.index !== undefined &&
          destination.index < source.index) ||
        destination.parentId !== source.parentId;

      const index =
        destination.index !== undefined
          ? destination.index - (isGoingUp ? 1 : 0)
          : parent
          ? parent.children.length - 1
          : 0;

      const itemDestination = findChildByIndex(tree, parentId, index);

      const params: IOrderCategoryParams = {
        contentId: item.id,
        type: item.type,
        position: itemDestination ? itemDestination.position + 1 : 1,
        parentGroup: parentIdNumber,
        structuredData: item.structuredData,
        relatedSite: currentSiteID || null,
        language: lang.id,
      };

      await orderCategory(params);
      setItemDragging(null);
    }
  };

  const isEmpty = currentDataContent && currentDataContent.length === 0;
  const hasCategories = currentCategories.length > 0;
  const categoryName = currentStructuredData && currentStructuredData.title;
  const emptyStateProps = {
    message: hasCategories
      ? `To start using ${categoryName} categories, create as many of them as yo need.`
      : "To start using categories in your site, you must active all Content type packages as you need.",
    button: hasCategories ? `Create ${categoryName} category` : "Activate Content type packages",
    action: hasCategories ? toggleModal : () => setHistoryPush("/sites/settings/content-types"),
  };

  const mainDeleteModalAction = {
    title: "Yes, delete it",
    onClick: bulkDelete,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const mainDeleteGroupModalAction = {
    title: "Delete",
    onClick: bulkDelete,
  };

  const secondaryDeleteGroupModalAction = { title: "Cancel", onClick: toggleGroupModal };

  return (
    <MainWrapper
      title="Categories"
      rightButton={rightButtonProps}
      language={lang}
      availableLanguages={availableLanguages}
      languageActions={languageActions}
      searchAction={setSearchQuery}
      searchValue={searchQuery}
    >
      <S.CategoryListWrapper>
        <CategoryNav
          current={currentStructuredData}
          categories={currentCategories}
          onClick={handleClick}
          dataPacks={activatedDataPacks}
        />
        <S.TableWrapper>
          <ErrorToast />
          <S.Intro>
            <S.IntroTitle>Create and manage categories</S.IntroTitle>
            <div>Define your categories by creating the elements you need and asign them to your content types.</div>
          </S.Intro>
          <TableList tableHeader={TableHeader} onScroll={onScroll} hasFixedHeader={true} tableRef={tableRef}>
            <S.SearchTags>
              <SearchTagsBar query={searchQuery} setQuery={setSearchQuery} />
              <FilterTagsBar
                filters={filterValues}
                setFilters={setFiltersSelection}
                resetFilters={resetFilterQuery}
                labels={{ translated: "Translated" }}
              />
            </S.SearchTags>
            {isEmpty ? (
              <S.EmptyWrapper>
                <EmptyState {...emptyStateProps} />
              </S.EmptyWrapper>
            ) : (
              <>
                <S.Notification>
                  Arrange your category list by <strong>drag & drop</strong>. You can move categories between groups,
                  but <strong>it&apos;s not possible to nest one category within another to create a new group.</strong>
                </S.Notification>
                <S.Droppable>
                  <Tree
                    tree={tree}
                    renderItem={renderItem}
                    onExpand={onExpand}
                    onCollapse={onCollapse}
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                    isDragEnabled
                    isNestingEnabled
                  />
                </S.Droppable>
              </>
            )}
          </TableList>
        </S.TableWrapper>
      </S.CategoryListWrapper>
      {isOpen && (
        <CategoryPanel isOpen={isOpen} toggleModal={toggleModal} getContents={getContents} toggleToast={toggleToast} />
      )}
      {isDeleteOpen && (
        <DeleteModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
        />
      )}
      {isGroupOpen && (
        <DeleteGroupModal
          isOpen={isGroupOpen}
          toggleModal={toggleGroupModal}
          mainModalAction={mainDeleteGroupModalAction}
          secondaryModalAction={secondaryDeleteGroupModalAction}
          deleteGroupCategories={deleteGroupCategories}
          setDeleteGroupCategories={setDeleteGroupCategories}
        />
      )}
      {isVisible && <Toast {...toastProps} />}
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  categories: state.structuredData.categories,
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  siteLanguages: state.sites.currentSiteLanguages,
  globalLangs: state.app.globalLangs,
  currentDataContent: state.structuredData.currentDataCategory,
  currentStructuredData: state.structuredData.currentStructuredData,
  lang: state.app.lang,
  totalItems: state.sites.totalItems,
  activatedDataPacks: state.dataPacks.activated,
});

interface IDispatchProps {
  getStructuredDataContents(params: IGetStructuredDataParams, siteID: number | null): Promise<void>;
  setLanguage(lang: { locale: string; id: number | null }): void;
  setSelectedCategory(id: string, scope: string): void;
  deleteDataContent(catID: number[], refresh?: boolean): Promise<boolean>;
  setHistoryPush(path: string): void;
  resetCurrentData(): Promise<void>;
  deleteCategoryGroup(id: number | number[], deleteChildren: boolean, refresh?: boolean): Promise<boolean>;
  orderCategory(params: IOrderCategoryParams): Promise<boolean>;
}

interface ICategoriesProps {
  currentSiteID: number | null;
  currentDataContent: (IStructuredDataCategory | ICategoryGroup)[];
  currentStructuredData: IStructuredData | null;
  lang: { locale: string; id: number };
  siteLanguages: any[];
  globalLangs: any[];
  totalItems: number;
  categories: { global: IStructuredData[]; site: IStructuredData[] };
  activatedDataPacks: IDataPack[];
}

type IProps = ICategoriesProps & IDispatchProps;

const mapDispatchToProps = {
  getStructuredDataContents: structuredDataActions.getStructuredDataContents,
  setLanguage: appActions.setLanguage,
  setSelectedCategory: structuredDataActions.setSelectedCategory,
  deleteDataContent: structuredDataActions.deleteStructuredDataContent,
  deleteCategoryGroup: structuredDataActions.deleteCategoryGroup,
  setHistoryPush: appActions.setHistoryPush,
  resetCurrentData: structuredDataActions.resetCurrentData,
  orderCategory: structuredDataActions.orderCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
