import React, { useState } from "react";
import { connect } from "react-redux";

import { structuredDataActions } from "@ax/containers/StructuredData";
import { useModal, usePermission } from "@ax/hooks";
import { ICheck, IRootState, IDataLanguage, IStructuredDataCategory, ILanguage, ICategoryGroup } from "@ax/types";
import { CheckField, FloatingMenu, Flag, LanguageMenu, Icon, Tag } from "@ax/components";
import { structuredData } from "@ax/api";
import { isReqOk } from "@ax/helpers";
import CategoryPanel from "../CategoryPanel";
import { DeleteGroupModal, DeleteModal } from "../atoms";
import { isCategory, isCategoryGroup } from "../utils";

import * as S from "./style";

const CategoryItem = (props: ICategoryItemProps): JSX.Element => {
  const {
    category,
    deleteStructuredDataContent,
    lang,
    languages,
    isTranslatable,
    isSelected,
    onChange,
    toggleToast,
    getContents,
    currentSiteID,
    dragHandleProps,
    icon,
    deleteCategoryGroup,
    hoverCheck,
  } = props;

  const { isOpen, toggleModal } = useModal();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isGroupOpen, toggleModal: toggleGroupModal } = useModal();
  const [deleteGroupCategories, setDeleteGroupCategories] = useState(false);
  const [translation, setTranslation] = useState<{
    lang: { locale: string; id: number };
    isNew: boolean;
    content: IStructuredDataCategory | ICategoryGroup | null;
  } | null>(null);

  const allowedToEditSiteCategory = usePermission("categories.editSiteTaxonomies");
  const allowedToEditGlobalCategory = usePermission("global.globalData.editTaxonomies");
  const allowedToDeleteSiteCategory = usePermission("categories.deleteSiteTaxonomies");
  const allowedToDeleteGlobalCategory = usePermission("global.globalData.deleteTaxonomies");
  const allowedToEditTaxonomy = currentSiteID ? allowedToEditSiteCategory : allowedToEditGlobalCategory;
  const allowedToDeleteTaxonomy = currentSiteID ? allowedToDeleteSiteCategory : allowedToDeleteGlobalCategory;

  const { locale } = lang;
  const { dataLanguages = [], content } = category;
  const isGroup = category.type === "group";

  const handleClick = () => toggleModal();

  const handleCloseModal = () => {
    setTranslation(null);
    toggleModal();
  };

  const handleOnChange = (value: ICheck) => onChange(value);

  const removeCategory = async () => {
    const categoryIDs = dataLanguages.length > 0 ? dataLanguages.map((lang: IDataLanguage) => lang.id) : category.id;
    const deleted = await deleteStructuredDataContent(categoryIDs);

    if (deleted) {
      isDeleteOpen && toggleDeleteModal();
      toggleToast("1 category deleted");
    }
  };

  const removeGroup = async () => {
    const categoryIDs =
      dataLanguages && dataLanguages.length > 0 ? dataLanguages.map((lang: IDataLanguage) => lang.id) : category.id;
    const deleted = await deleteCategoryGroup(categoryIDs, deleteGroupCategories);

    if (deleted) {
      isGroupOpen && toggleGroupModal();
      toggleToast(
        deleteGroupCategories ? "1 grouping category and its categories deleted" : "1 grouping category deleted"
      );
    }
  };

  const menuOptions = allowedToDeleteTaxonomy
    ? [
        {
          label: "delete",
          icon: "delete",
          action: isGroup ? toggleGroupModal : toggleDeleteModal,
        },
      ]
    : [];

  const getCurrentLanguages = () => {
    const availables: any[] = [];

    dataLanguages &&
      dataLanguages.forEach(
        (dataLang: any) =>
          languages &&
          languages.forEach((language) => {
            if (language.id === dataLang.language) {
              availables.push(language);
            }
          })
      );

    return availables;
  };

  const currentLanguages = getCurrentLanguages();

  const getDataTranslation = async (
    dataID: number,
    langID: number,
    type: "category" | "group"
  ): Promise<IStructuredDataCategory | ICategoryGroup | null> => {
    const response =
      type === "group" ? await structuredData.getGroup(dataID) : await structuredData.getDataContent(dataID, langID);
    if (isReqOk(response?.status)) {
      return response.data;
    } else {
      console.log("Error en getDataTranslation");
      return null;
    }
  };

  const handleLanguage = (language: ILanguage) => async () => {
    const lang = {
      locale: language.locale,
      id: language.id,
    };

    const isNew = currentLanguages.find((l) => l.id === language.id) ? false : true;
    let content: IStructuredDataCategory | ICategoryGroup | null = null;
    if (!isNew) {
      const translationLang = category.dataLanguages.find((dataLang) => dataLang.language === lang.id);
      content = await getDataTranslation(translationLang?.id || category.id, lang.id, category.type);
    }
    setTranslation({ lang, isNew, content });
    toggleModal();
  };

  const languageMenu = () => (
    <LanguageMenu
      language={locale}
      availableLanguages={languages}
      setLanguage={handleLanguage}
      currentLanguages={currentLanguages}
    />
  );

  const FlagsButton = () => (
    <S.FlagsWrapper>
      {currentLanguages.slice(0, 2).map((pageLanguage: any, i: number) => (
        <Flag key={`${pageLanguage.language}${i}`} name={pageLanguage.locale} size="15" />
      ))}
      <span>({currentLanguages.length})</span>
    </S.FlagsWrapper>
  );

  const translations = isTranslatable ? (
    <FloatingMenu Button={FlagsButton}>{languageMenu()}</FloatingMenu>
  ) : (
    "Not translatable"
  );

  const mainDeleteModalAction = {
    title: "Yes, delete it",
    onClick: removeCategory,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const mainDeleteGroupModalAction = {
    title: "Delete",
    onClick: removeGroup,
  };

  const secondaryDeleteGroupModalAction = { title: "Cancel", onClick: toggleGroupModal };

  const selectable = isCategoryGroup(category) && category.selectable === false ? false : true;

  return (
    <>
      <S.CategoryRow role="rowgroup" selected={isSelected}>
        <S.HandleCell>
          <S.IconWrapperDrag role="cell" {...dragHandleProps}>
            <Icon name="drag" size="16" />
          </S.IconWrapperDrag>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </S.HandleCell>
        <S.CheckCell role="cell">
          <CheckField name="check" value={category.id} checked={isSelected || hoverCheck} onChange={handleOnChange} />
        </S.CheckCell>
        <S.NameCell role="cell" onClick={handleClick} clickable={allowedToEditTaxonomy} isGroup={isGroup}>
          {isGroup && (
            <S.FolderWrapper>
              <Icon name="project" size="24" />
            </S.FolderWrapper>
          )}
          <div>{content?.title || ""}</div>
        </S.NameCell>
        <S.CodeCell role="cell" onClick={handleClick} clickable={allowedToEditTaxonomy}>
          {isCategory(category) && category.content ? category.content.code : ""}
        </S.CodeCell>
        <S.SelectableCell role="cell">
          <Tag
            type="square"
            color={selectable ? "" : "#FFD4C7"}
            text={selectable ? "Yes" : "No"}
            textColor={selectable ? "" : "#20224C"}
          />
        </S.SelectableCell>
        <S.TransCell role="cell">{translations}</S.TransCell>
        <S.ActionsCell role="cell">
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Actions" />
        </S.ActionsCell>
      </S.CategoryRow>
      {isOpen && (
        <CategoryPanel
          isOpen={isOpen}
          toggleModal={handleCloseModal}
          item={translation?.content || category}
          getContents={getContents}
          translation={translation}
          toggleToast={toggleToast}
        />
      )}
      {isDeleteOpen && (
        <DeleteModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
        />
      )}
      {isGroupOpen && (
        <DeleteGroupModal
          isOpen={isGroupOpen}
          toggleModal={toggleGroupModal}
          mainModalAction={mainDeleteGroupModalAction}
          secondaryModalAction={secondaryDeleteGroupModalAction}
          deleteGroupCategories={deleteGroupCategories}
          setDeleteGroupCategories={setDeleteGroupCategories}
        />
      )}
    </>
  );
};

interface IProps {
  category: IStructuredDataCategory | ICategoryGroup;
  languages: any[];
  lang: { locale: string; id: number | null };
  isTranslatable: boolean;
  isSelected: boolean;
  onChange: (e: any) => void;
  toggleToast(state: any): void;
  getContents(dataId: string): void;
  currentSiteID: number | null;
  icon: JSX.Element;
  dragHandleProps?: any;
  hoverCheck?: boolean;
}

interface IDispatchProps {
  deleteStructuredDataContent(id: number | number[]): Promise<boolean>;
  deleteCategoryGroup(id: number | number[], deleteChildren: boolean): Promise<boolean>;
}

type ICategoryItemProps = IProps & IDispatchProps;

const mapStateToProps = (state: IRootState) => ({
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
});

const mapDispatchToProps = {
  deleteStructuredDataContent: structuredDataActions.deleteStructuredDataContent,
  deleteCategoryGroup: structuredDataActions.deleteCategoryGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryItem);
