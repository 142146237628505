import _Object$assign from "core-js/library/fn/object/assign.js";
function _extends() {
  return _extends = _Object$assign ? _Object$assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
export { _extends as default };