import { usePage } from "./usePage";
import { Core } from "..";

const INVALID_DATE = "Invalid Date";

/**
 * Format and translate a string date from a `DateField` (YY/MM/DD) responding
 * to different Locales from page context. Also support an option object
 * (Intl.DateTimeFormatOptions) to customize the output.
 *
 * @example
 * const { date } = useLocaleDate("2023/08/06")
 * // 6 Agosto 2023
 */
function useLocaleDate(
	date?: string,
	options: Intl.DateTimeFormatOptions = {
		dateStyle: "long",
	}
) {
	const { ISOLocale: locale } = usePage();

	if (!date) {
		return { date: undefined, dateTime: undefined };
	}

	return formatLocaleDate(date, locale || "en-US", options);
}

/**
 * Format and translate a string date from a `DateField` (YY/MM/DD) into
 * differentes Locales. You can pass an option object Intl.DateTimeFormatOptions
 * to customize the output.
 *
 * @example
 * formatLocaleDate({ dateString: "2023/08/06", locale: "es-ES"})
 * // { date: "6 Agosto 2023", dateTime: "2023-08-06" }
 */
function formatLocaleDate(
	inputDate: string,
	locale: Core.ISOLocale,
	options: Intl.DateTimeFormatOptions = { dateStyle: "long" }
) {
	if (!isDateStyleSupported) {
		return { date: inputDate, dateTime: "" };
	}

	const date = new Date(inputDate);

	if (isNaN(date.getTime())) {
		return {
			date: INVALID_DATE,
			dateTime: INVALID_DATE,
		};
	}

	const localeDate = date.toLocaleDateString(locale, options);
	const dateTimeDate = inputDate.replaceAll("/", "-");

	return {
		date: localeDate,
		dateTime: dateTimeDate,
	};
}

function isDateStyleSupported(locale: string) {
	return (
		Intl.DateTimeFormat.supportedLocalesOf(locale, {
			dateStyle: "long",
		}).length > 0
	);
}

export { formatLocaleDate, useLocaleDate };
